import React, { useState, useEffect } from "react";
// import New1 from "../../assets/ecpyrose.jpeg";
// eslint-disable-next-line
import New2 from "../../assets/marseille.jpg";
import New3 from "../../assets/bilanpositif.jpeg";
import New4 from "../../assets/tenebrae.jpg";
import New5 from "../../assets/ecpyrose.jpg";
import New6 from "../../assets/brooks.jpg";
import New7 from "../../assets/gogolady.jpg";
import New8 from "../../assets/groupiedestars.jpg";
import New9 from "../../assets/kannelle.jpg";
import "./News.css";
import { motion } from "framer-motion";

const News2 = () => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsVisible((prevVisible) => !prevVisible);
    }, 500); // Réglez la durée du clignotement en millisecondes

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div
      className="max-sm:pt-[30px] max-sm:h-[100%] md:h-[100%] h-[100%] py-6 flex items-center justify-center bg-black text-white max-sm:flex-col overflow-hidden"
      id="news"
    >
      <div className="flex flex-col">
        <h2 className="text-center font-oswald text-[2rem] mb-3 pt-5 max-sm:text-[30px]">
          ACTUALITÉS
        </h2>
        <div className="border border-solid border-1 border-fe955a bg-fe955a max-w-[150px] mx-auto"></div>

        <div className="flex justify-center mt-2">
          <div className="flex flex-col gap-y-[100px]">
            {/* NEW 1 */}
            <div className=" w-full">
              <div className="flex max-sm:pt-5 gap-9 justify-center items-center max-sm:flex-col">
                <div>
                  <img
                    className="w-[400px] max-sm:mx-auto max-sm:max-w-[300px] mr-2"
                    src={New6}
                    alt=""
                  />
                </div>

                <div className="flex flex-col max-sm:items-center">
                  <motion.h1
                    className="text-[40px] font-openSans max-sm:text-[30px]"
                    variants={{
                      hidden: { opacity: 0 },
                      show: {
                        opacity: 1,
                        transition: { duration: 0.5, yoyo: Infinity },
                      },
                    }}
                    initial="hidden"
                    animate={isVisible ? "show" : "hidden"}
                    viewport={{ once: false, amount: 0.3 }}
                    style={{ color: "#fe90ab" }}
                  >
                    EP ÉVÈNEMENT !
                  </motion.h1>
                  <div className="max-w-[600px]  max-lg:max-w-[300px]">
                    <p className="font-openSans text-[16px] max-sm:text-sm max-sm:text-center text-justify font-bold text-white opacity-90 mb-3 leading-6">
                      « WHAT A PARADISE ! »
                    </p>
                    <p className="font-openSans text-justify text-[16px] max-sm:text-[16px] max-sm:text-center font-bold text-white opacity-90 mb-3 leading-6">
                      Une pépite vintage disponible sur toutes les plateformes.
                    </p>
                    <p className="text-[16px] text-justify font-openSans leading-7">
                      Disponible pour la première fois en version remasterisée
                      depuis sa sortie en 1982, « What a paradise ! » nous
                      ramène aux inoubliables tubes feel-good des eighties !
                      Dans la mouvance des Buggles, Cars ou encore Katrina &amp;
                      The Wave, cette chanson boogie-pop est interprétée par un
                      certain Brooks, derrière lequel se cache le frenchy F.R.
                      David, futur interprète du hit planétaire « Words » !
                      <br /> Découvrez cet EP, en passe de devenir culte…
                    </p>

                    <div className="flex flex-col justify-start">
                      <div className="max-sm:mx-auto news-link">
                        <a
                          href="https://m-s.lnk.to/WAP-CSCC"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Écouter
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* NEW 1 */}
            <div className=" w-full">
              <div className="flex max-sm:pt-5 gap-9 justify-center items-center max-sm:flex-col">
                <div>
                  <img
                    className="w-[400px] max-sm:mx-auto max-sm:max-w-[300px] mr-2"
                    src={New7}
                    alt=""
                  />
                </div>

                <div className="flex flex-col max-sm:items-center">
                  <motion.h1
                    className="text-[40px] font-openSans max-sm:text-[30px]"
                    variants={{
                      hidden: { opacity: 0 },
                      show: {
                        opacity: 1,
                        transition: { duration: 0.5, yoyo: Infinity },
                      },
                    }}
                    initial="hidden"
                    animate={isVisible ? "show" : "hidden"}
                    viewport={{ once: false, amount: 0.3 }}
                    style={{ color: "#cf722d" }}
                  >
                    EP GIRLY !
                  </motion.h1>
                  <div className="max-w-[600px]  max-lg:max-w-[300px]">
                    <p className="font-openSans text-[16px] max-sm:text-sm max-sm:text-center text-justify font-bold text-white opacity-90 mb-3 leading-6">
                      « GOGO LADY »
                    </p>
                    <p className="font-openSans text-justify text-[16px] max-sm:text-[16px] max-sm:text-center font-bold text-white opacity-90 mb-3 leading-6">
                      Girl power from the 70’s !
                    </p>
                    <p className="text-[16px] text-justify font-openSans leading-7">
                      Avant d’enflammer les charts de 1976 avec « Gogo Lady »,
                      reprise frenchy du hit de Johnny Taylor « Disco Lady »,
                      les sœurs Delahaye sont les It-girls des cabarets chics,
                      de Saint-Tropez à Paris, avec leur show de
                      danseuses-acrobates. Le temps d’un unique 45 tours, elles
                      deviennent les Delahaye Sisters. Aux lyrics, les paroliers
                      de Bardot, Dalida, Hardy ; au beat soul-funk, l’arrangeur
                      de Polnareff et Claude François. Une success-story made in
                      70’s à redécouvrir en version remasterisée !
                    </p>

                    <div className="flex flex-col justify-start">
                      <div className="max-sm:mx-auto news-link">
                        <a
                          href="https://delahayesisters.lnk.to/Gogolady"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Écouter
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* NEW 1 */}
            <div className=" w-full">
              <div className="flex max-sm:pt-5 gap-9 justify-center items-center max-sm:flex-col">
                <div>
                  <img
                    className="w-[400px] max-sm:mx-auto max-sm:max-w-[300px] mr-2"
                    src={New9}
                    alt=""
                  />
                </div>

                <div className="flex flex-col max-sm:items-center">
                  <motion.h1
                    className="text-[40px] font-openSans max-sm:text-[30px]"
                    variants={{
                      hidden: { opacity: 0 },
                      show: {
                        opacity: 1,
                        transition: { duration: 0.5, yoyo: Infinity },
                      },
                    }}
                    initial="hidden"
                    animate={isVisible ? "show" : "hidden"}
                    viewport={{ once: false, amount: 0.3 }}
                    style={{ color: "#857649" }}
                  >
                    EP pop-rock vintage !
                  </motion.h1>
                  <div className="max-w-[600px]  max-lg:max-w-[300px]">
                    <p className="font-openSans text-[16px] max-sm:text-sm max-sm:text-center text-justify font-bold text-white opacity-90 mb-3 leading-6">
                    </p>
                    <p className="font-openSans text-justify text-[16px] max-sm:text-[16px] max-sm:text-center font-bold text-white opacity-90 mb-3 leading-6">
                    « Beau comme une femme » et « La fin d’une époque ». 
                    </p>
                    <p className="text-[16px] text-justify font-openSans leading-7">
                      Reine anonyme des nuits parisiennes des 80’s, c’est
                      quelque part entre le Palace et les Bains Douches que
                      Marie-Pierre gagne le surnom de Kannelle, pour son côté
                      « sweat and spicy des iles » dixit Gainsbourg, selon la
                      légende. Souvent, elle partage ses drinks avec Bashung,
                      Daho, Grace Jones… Et comme un éclair, Kannelle devient
                      chanteuse-comète de deux météorites pop-rock : « Beau
                      comme une femme » et « La fin d’une époque ». À découvrir
                      pour la première fois en version remasterisée !
                    </p>

                    <div className="flex flex-col justify-start">
                      <div className="max-sm:mx-auto news-link">
                        <a
                          href="https://mpkannelle.lnk.to/beaucommeunefemme"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Écouter
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* NEW 1 */}
            <div className=" w-full">
              <div className="flex max-sm:pt-5 gap-9 justify-center items-center max-sm:flex-col">
                <div>
                  <img
                    className="w-[400px] max-sm:mx-auto max-sm:max-w-[300px] mr-2"
                    src={New8}
                    alt=""
                  />
                </div>

                <div className="flex flex-col max-sm:items-center">
                  <motion.h1
                    className="text-[40px] font-openSans max-sm:text-[30px]"
                    variants={{
                      hidden: { opacity: 0 },
                      show: {
                        opacity: 1,
                        transition: { duration: 0.5, yoyo: Infinity },
                      },
                    }}
                    initial="hidden"
                    animate={isVisible ? "show" : "hidden"}
                    viewport={{ once: false, amount: 0.3 }}
                    style={{ color: "#ebd044" }}
                  >
                    EP NOSTALGIE 80'S !
                  </motion.h1>
                  <div className="max-w-[600px]  max-lg:max-w-[300px]">
                    <p className="font-openSans text-[16px] max-sm:text-sm max-sm:text-center text-justify font-bold text-white opacity-90 mb-3 leading-6">
                      « GROUPIE DE STARS »
                    </p>
                    <p className="font-openSans text-justify text-[16px] max-sm:text-[16px] max-sm:text-center font-bold text-white opacity-90 mb-3 leading-6">
                      Pop-songs des eighties !
                    </p>
                    <p className="text-[16px] text-justify font-openSans leading-7">
                      Enfant tumultueuse de la côte Atlantique, Marine se rêve
                      comédienne. À 18 ans, direction Paris, où elle enchaîne
                      pubs et shootings. Puis elle rencontre le parolier
                      Jean-Luc Morel, qui a déjà signé des tubes pour F.R. David
                      et Dalida. Crush, love story et chansons naîtront de leur
                      duo ! C’est avec « Groupie de Stars » que Marine impose sa
                      voix Mylène Farmer en plus teenager. Direction le happy
                      dancefloor avec cet EP pop et acidulé en version
                      remasterisée.
                    </p>

                    <div className="flex flex-col justify-start">
                      <div className="max-sm:mx-auto news-link">
                        <a
                          href="https://marine.lnk.to/groupiedestars"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Écouter
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* NEW 2 */}
            <div className=" w-full">
              <div className="flex max-sm:pt-5 gap-9 justify-center items-center max-sm:flex-col">
                <div>
                  <img
                    className="w-[400px] max-sm:mx-auto max-sm:max-w-[300px] mr-2"
                    src={New4}
                    alt=""
                  />
                </div>

                <div className="flex flex-col max-sm:items-center">
                  <div className="max-w-[600px]  max-lg:max-w-[300px]">
                    <p className="font-openSans text-[16px] max-sm:text-sm max-sm:text-center text-justify font-bold text-white opacity-90 mb-3 leading-6">
                      « TENEBRAE », le nouvel EP de Florent Richard
                    </p>

                    <p className="text-[16px] text-justify font-openSans leading-7">
                      Avec « Tenebrae », Florent Richard entre dans la danse
                      macabre sur un implacable beat drum and bass, délivrant un
                      slam intense à mi-chemin entre Gainsbourg et Martin
                      Luminet. Et dans sa version instrumentale, également
                      disponible sur l’EP, « Tenebrae » rappelle les soundtracks
                      culte de films d’horreur composés par John Carpenter,
                      Goblin ou encore Fabio Frizzi.
                    </p>

                    <div className="flex flex-col justify-start">
                      <div className="max-sm:mx-auto news-link">
                        <a
                          href="https://wiseband.lnk.to/Florent-Richard-Tenebrae"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Écouter
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* NEW 2 */}
            <div className=" w-full">
              <div className="flex max-sm:pt-5 gap-9 justify-center items-center max-sm:flex-col">
                <div>
                  <img
                    className="w-[400px] max-sm:mx-auto max-sm:max-w-[300px] mr-2"
                    src={New2}
                    alt=""
                  />
                </div>

                <div className="flex flex-col max-sm:items-center">
                  <div className="max-w-[600px]  max-lg:max-w-[300px]">
                    <p className="font-openSans text-[16px] max-sm:text-sm max-sm:text-center text-justify font-bold text-white opacity-90 mb-3 leading-6">
                      « MARSEILLE JE N'TE RECONNAIS PLUS »
                    </p>

                    <p className="text-[16px] text-justify font-openSans leading-7">
                      En 1983, Franck Fernandel interprète de sa voix
                      ensoleillée « Marseille, je n&#39;te reconnais plus »,
                      chanson coup de poing, visionnaire et enfiévrée, portée
                      par un furieux beat néo-disco signé Yan Tregger, pape de
                      la musique électro-pop des eighties, et un texte au
                      scalpel de Léo Carrier. 40 ans après sa sortie, cette
                      chanson s&#39;impose comme le miroir saisissant d’une
                      ville en pleine mutation. « Marseille, je n’te reconnais
                      plus » est désormais disponible en version remasterisée
                      depuis les bandes studio d’origine.
                    </p>

                    <div className="flex flex-col justify-start">
                      <div className="max-sm:mx-auto news-link">
                        <a
                          href="https://franckfernandel.lnk.to/marseille"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Écouter
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* NEW 2 */}
            <div className=" w-full">
              <div className="flex max-sm:pt-5 gap-9 justify-center items-center max-sm:flex-col">
                <div>
                  <img
                    className="w-[400px] max-sm:mx-auto max-sm:max-w-[300px] mr-2"
                    src={New5}
                    alt=""
                  />
                </div>

                <div className="flex flex-col max-sm:items-center">
                  <div className="max-w-[600px]  max-lg:max-w-[300px]">
                    <p className="text-[16px] text-justify font-openSans leading-7">
                      « Ecpyrose » est un album baroque, aux confluences de
                      l’électro-pop et de la musique symphonique, porté par la
                      voix chaude de Florent Richard, avec l’incendiaire vérité
                      de l’intime en toile de fond. Huit chansons ardentes et
                      poétiques, fragments d’une vie d’homme où les derniers
                      possibles se rêvent, se consomment puis se consument.
                      Jusqu’à l’ecpyrose.
                    </p>
                    <p className="text-[16px] text-justify font-openSans leading-7">
                      Les titres de ce nouvel opus ont été composés par Florent
                      Richard et co-arrangés avec Yannick Soccal, sur des textes
                      de Vincent Fernandel.
                    </p>

                    <div className="flex flex-col justify-start">
                      <div className="max-sm:mx-auto news-link">
                        <a
                          href="https://wiseband.lnk.to/Florent-Richard-Ecpyrose-album"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Écouter
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* NEW 3*/}
            <div className=" w-full">
              <div className="flex max-sm:pt-5 gap-5 justify-center items-center max-sm:flex-col">
                <div>
                  <img
                    className="w-[400px] max-sm:mx-auto max-sm:max-w-[300px] mr-2"
                    src={New3}
                    alt=""
                  />
                </div>

                <div className="flex flex-col max-sm:items-center">
                  <div className="max-w-[600px] max-sm:max-w-80 max-lg:max-w-[300px]">
                    <p className="font-openSans text-[16px] max-sm:text-sm max-sm:text-center text-justify font-bold text-white opacity-90 mb-3 leading-6">
                      FRANCK FERNANDEL - BILAN POSITIF (1983)
                    </p>
                    <p className="text-[16px] text-justify font-openSans leading-7">
                      Découvrez pour la première fois en digital cet album
                      incontournable de Franck Fernandel. Dix chansons
                      entièrement remasterisées, dont "L'amour interdit", tube
                      des années 80.
                    </p>

                    <div className="flex flex-col justify-start">
                      <div className="news-link max-sm:mx-auto">
                        <a
                          href="https://fernandel.lnk.to/bilanpositif"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          ÉCOUTER
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default News2;
